import React, { useState } from "react";
import Stars from "../global/Customreviews/Stars";



function Faq() {

    const ReadMore = ({ children }) => {
        const text = children;
        const [isReadMore, setIsReadMore] = useState(true);
        const toggleReadMore = () => {
            setIsReadMore(!isReadMore);
        };
        return (
            <p className="text">
                {isReadMore ? text.slice(0, 100) : text}
                <span onClick={toggleReadMore} className="read-or-hide text-gray-400">
                    {isReadMore ? "...read more" : " show less"}
                </span>
            </p>
        );
    };
    const moverReview = [
        {
          UserName: "Pro Roofing...",
          Date: "1 years ago",
          Rate: 5,
          Description: "We use this company for all our tree removal services and they are excellent. We have tried many companies in GA is this is the best so far. Always with safety equipment. Price is fair and their process is quick.",
          red: "Google",
          enlace: "https://g.page/r/CdPrj1BejY2UEBM/review",
          ima: "https://firebasestorage.googleapis.com/v0/b/resource-36ef7.appspot.com/o/resource%2F300221-compressed.jpg?alt=media&token=c478ae77-517d-4f9f-92d6-40fed13e8de6"
        },
        {
          UserName: "Ernestine Sco..",
          Date: "5 month ago",
          Rate: 5,
          Description: "They did an excellent job cutting down our tree and cleaning up everything.",
          red: "Google",
          enlace: "https://g.page/r/CdPrj1BejY2UEBM/review",
          ima: "https://firebasestorage.googleapis.com/v0/b/resource-36ef7.appspot.com/o/resource%2F300221-compressed.jpg?alt=media&token=c478ae77-517d-4f9f-92d6-40fed13e8de6"
        },
        {
          UserName: "Manuel Rami..",
          Date: "1 years ago",
          Rate: 5,
          Description: "excellent service and great price. recommended 100%.",
          red: "Google",
          enlace: "https://g.page/r/CdPrj1BejY2UEBM/review",
          ima: "https://firebasestorage.googleapis.com/v0/b/resource-36ef7.appspot.com/o/resource%2F300221-compressed.jpg?alt=media&token=c478ae77-517d-4f9f-92d6-40fed13e8de6"
        },
        {
          UserName: "Amy Vergara.",
          Date: "9 month ago",
          Rate: 3.7,
          Description: "Uú me",
          red: "Google",
          enlace: "https://g.page/r/CdPrj1BejY2UEBM/review",
          ima: "https://firebasestorage.googleapis.com/v0/b/resource-36ef7.appspot.com/o/resource%2F300221-compressed.jpg?alt=media&token=c478ae77-517d-4f9f-92d6-40fed13e8de6"
        },
        {
          UserName: "William plays...",
          Date: "5 month ago",
          Rate: 5,
          Description: "Is a w",
          red: "Google",
          enlace: "https://g.page/r/CdPrj1BejY2UEBM/review",
          ima: "https://firebasestorage.googleapis.com/v0/b/resource-36ef7.appspot.com/o/resource%2F300221-compressed.jpg?alt=media&token=c478ae77-517d-4f9f-92d6-40fed13e8de6"
        },
        {
          UserName: "Lazaro An...",
          Date: "1 years ago",
          Rate: 4.6,
          Description: "",
          red: "Google",
          enlace: "https://g.page/r/CdPrj1BejY2UEBM/review",
          ima: "https://firebasestorage.googleapis.com/v0/b/resource-36ef7.appspot.com/o/resource%2F300221-compressed.jpg?alt=media&token=c478ae77-517d-4f9f-92d6-40fed13e8de6"
        },
     
      ]
      
     


    return (
        <section className="w-4/5 h-auto my-10 mx-auto gap-5 grid md:grid-cols-3 grid-cols-1">
            {
                moverReview.map((item, index) => {
                    return (
                        <article key={index} className="h-auto p-2 flex flex-col rounded-3xl bg-gray-100 w-auto">
                            <div className="flex flex-col">
                                <div className="p-2 flex space-x-2">
                                    <div className="flex justify-center">
                                        <img src="https://firebasestorage.googleapis.com/v0/b/imagenes-fc412.appspot.com/o/user.jfif?alt=media&token=2e7c59b5-a71c-4739-8260-bb88b37b08fa"
                                            alt="perfil-foto"
                                            className="w-[50px] h-auto rounded-full object-cover" />
                                    </div>
                                    <div>
                                        <span className="text-[20px] font-semibold"> {item?.UserName} </span>
                                        <Stars score={item?.Rate} />
                                    </div>
                                    <div className="flex items-end text-[13px] text-gray-400">
                                        <span>{item?.Date}</span>
                                    </div>
                                </div>
                            </div>
                            <div className="p-5 ">
                                <ReadMore >{item?.Description}</ReadMore>
                            </div>
                            <div className="w-full flex items-center space-x-3 ml-3">
                                <div className="w-[25px] h-[25px] flex items-center">
                                    <img src={item?.ima} alt="agni" className="w-full mx-auto" />
                                </div>
                                <div className="flex flex-col items-center justify-start">
                                    <p>Posted On</p>
                                    <a className="text-blue-500 w-full" href={item?.enlace}>{item?.red}</a>
                                </div>
                            </div>
                        </article>
                    );
                })
            }
        </section>
    );
}

export default Faq;