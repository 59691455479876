import React, { useState, useContext } from "react";
import { GlobalDataContext } from "../context/context";
import TransparentHeader from "../components/global/TransparentHeader";
import BaseLayout from "../components/global/BaseLayout";
import CustomReviews from "../components/reviews/CustomReviews";
import LoadingSpinner from "../components/global/Loader";
import { BiLogInCircle } from "react-icons/bi";
import UserTag from "../components/reviews/userReview";
import { useSigninCheck } from "reactfire";
import ModalRegister from "../components/reviews/ModalRegister";

function Reviews() {
  const { rpdata } = useContext(GlobalDataContext);
  const [modalRegister, setModalRegister] = useState(false);

  const { status, data: signinResult } = useSigninCheck();
  if (status === "loading") {
    return <LoadingSpinner />;
  }
  const { signedIn, user } = signinResult;

  return (
    <BaseLayout PageName="Reviews">
      <div className="md:max-w-full w-full">
        <TransparentHeader
          headertitle="Reviews"
          Subheader="Reviews"
          bgimg={`${rpdata?.gallery?.[10]}`}
        />
      </div>
      <div className="w-[90%] md:w-4/5 mx-auto py-[100px]">
        <h1 className="text-center pb-4">
          {rpdata?.labels?.general?.titleReviews}
        </h1>
        {/* <div className={`${rpdata?.reviews?.links?.[0]}`}></div> */}

        <div className="w-full flex md:flex-col justify-between self-center items-center my-4">
          {signedIn !== true ? (
            <div className="flex flex-col md:flex-row justify-center items-center md:justify-between w-full">
              <span className="text-[30px] font-bold mx-3 text-center color-2">
                Leave Us a Review!
              </span>
              <button
                className="bg-white bg-btn text--slate-900 duration-300 ease-in-out hover:text-white  rounded-full px-5 py-2 w-[250px] flex items-center justify-center"
                onClick={() => setModalRegister(true)}
              >
                <span className="px-2 text-[20px] font-bold text-white">
                  Login
                </span>
                <BiLogInCircle className="text-[22px] font-bold text-white" />
              </button>
            </div>
          ) : (
            <UserTag id={user.uid} />
          )}
        </div>
        <div className="w-4/5 mx-auto flex flex-col md:flex-row gap-5 justify-between items-center mt-10">
          <picture className="w-full md:w-[20%]">
            <a
              href={`${rpdata?.gmb.link}`}
              target="_blank"
              rel="noreferrer"
            >

              <img
                src={`https://firebasestorage.googleapis.com/v0/b/clientesimages.appspot.com/o/directorios%2Fgmb-reviews.png?alt=media&token=791a077e-6f07-4a18-9226-32c0bf9cb2d1`}
                alt="Reviews"
                className="w-full h-auto"
              />
            </a>
          </picture>
          <div>
            <a
              href={`${rpdata?.gmb.link}`}
              target="_blank"
              rel="noreferrer"

            >
              <button className="bg-white bg-btn text--slate-900 duration-300 ease-in-out hover:text-white  rounded-full px-5 py-2 w-[250px] flex items-center justify-center">
                <span className="px-2 text-[20px] font-bold text-white">
                  Write A Review
                </span>
                <BiLogInCircle className="text-[22px] font-bold text-white" />
              </button>
            </a>
          </div>
        </div>
        <CustomReviews />
      </div>
      {modalRegister ? (
        <ModalRegister
          isModalLogout={modalRegister}
          onClose={() => setModalRegister(false)}
        />
      ) : null}
    </BaseLayout>
  );
}

export default Reviews;
